<template>
  <div class="p-3">
    <h2>Two factor authentication</h2>
    <div class="d-flex mt-5">
      <div class="d-flex flex-column">
        <div>
          <label class="mfa-checkbox">
            <input
              id="mailRecovery"
              class="mr-1"
              type="checkbox"
              v-model="mailRecovery"
            />
            Email QRCode
          </label>
        </div>
        <input
          @keydown.enter="verifyMfa"
          class="fluent mt-5"
          type="text"
          v-model="mfaToken"
          placeholder="Enter verification code"
          required
        />
        <form-error class="mt-2" />
        <button
          @click="verifyMfa"
          class="fluent mt-5 ms-fontColor-black ms-bgColor-sharedYellow10"
        >
          <label>Enable</label>
        </button>
      </div>
      <img class="d-block py-2 ml-auto" :src="qrcode" />
    </div>
  </div>
</template>

<script>
import FormError from "../forms/partials/FormError";
import { mapActions } from "vuex";

export default {
  components: {
    FormError
  },
  data: function() {
    return {
      qrcode: "",
      mailRecovery: false,
      mfaToken: ""
    };
  },
  computed: {
    isMfaEnabled() {
      return this.$store.state.mfaEnabled;
    }
  },
  methods: {
    ...mapActions(["postJsonToApi"]),
    async verifyMfa() {
      await this.postJsonToApi({
        url: "/settings/verify-mfa",
        mutation: "setMfaSettings",
        data: {
          qrcode: this.qrcode,
          mailRecovery: this.mailRecovery,
          mfaToken: this.mfaToken
        }
      });
    }
  },
  async beforeMount() {
    if (this.isMfaEnabled) this.$router.go(-1);

    const response = await fetch("/settings/add-mfa");
    const data = await response.json();
    this.qrcode = data.qrcode;
  }
};
</script>

<style scoped>
.mfa-checkbox:hover {
  cursor: pointer;
}
</style>
